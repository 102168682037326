(function () {
	'use strict';
	angular
		.module('measuring')
		.factory('manageMeasure', [
			'$rootScope',
			'seedcodeCalendar',
			'utilities',
			'manageFilters',
			'manageResources',
			'dataStore',
			manageMeasure,
		]);

	function manageMeasure(
		$rootScope,
		seedcodeCalendar,
		utilities,
		manageFilters,
		manageResources,
		dataStore
	) {
		return {
			createEventDayCount: createEventDayCount,
			showMeasure: showMeasure,
			hideMeasure: hideMeasure,
		};

		function getColors() {
			var config = seedcodeCalendar.get('config');

			var colors = config.measureColors
				? config.measureColors
				: [
						'#09c219',
						'#6188e2',
						'#d51b15',
						'#e28202',
						'#2db39f',
						'#59922b',
						'#0544d3',
						'#6b0392',
						'#f05b4f',
						'#b972a7',
						'#eacf7d',
						'#86797d',
						'#b2c326',
						'#f0678c',
						'#b972a7',
					];

			return colors;
		}

		function createEventDayCount(
			start,
			startOverride,
			end,
			callback,
			refetch
		) {
			var config = seedcodeCalendar.get('config');
			var view = seedcodeCalendar.get('view');
			var threshold = config.measureThreshold
				? Number(config.measureThreshold)
				: null;
			var weekFirstDay =
				!config.weekends &&
				(config.firstDay == 6 || config.firstDay == 0)
					? 1
					: Number(config.firstDay); //Number(config.firstDay);
			var weekMiddleDay = 3;
			var middleDurationOffset = 0;
			var propertyFormat;
			var durationType;
			var slotDuration;
			var showTimes;
			var date;
			var dateEnd;
			var dayGrid = {};
			var eventIDs = {};
			var summaryEventIDs = {};
			var summaryEventCount = {};
			var viewDurationCount;

			var breakoutField =
				view.name.indexOf('Horizon') > -1
					? config.horizonBreakoutField
					: null; // Only enable breakout field on horizon
			var breakout = config.breakout;
			var breakoutItems;
			var breakoutType;
			var defaultBreakoutID = 'data';
			var breakoutID;

			var columnsAreBreakout;
			var combineResources;

			var colorCount = 0;

			var dateString;
			var dateStringFormat;

			var measureField = config.measureField;

			var countType = config.measureType;

			var rangeType = 'day';
			var includeLastColumn = true;
			var startColumn;
			var endColumn;
			var columnCount = 0;
			var hasColumnOffset = false;
			var columnOffsetCount = 0;
			var columnGroupSize = 1;

			var colWidth = view.getColWidth();
			var colCount = view.getColCnt();

			var maxColCount = colCount;

			var paddingColumnCount = 0;
			var widthOffset = 0;
			var leftOffset = 0;

			var columnsBefore = 0;
			var columnsAfter = 0;
			var endsOnStartRange;

			var maxColumnPad = 0; //Can use this if the last column isn't shown in the view.

			var startPosition;
			var contentWidth;

			var afterLast;

			var hasAfter;
			var hasBefore;

			var dateID;
			var includeDate;

			var dateHash = {};
			var resourceDateHash;

			var durationCount = 0;
			var visibleChartPointCount = 0;

			var isEstimate;

			var durationColumns;

			var thresholdTotal; // Add one because end date is exclusive

			var clusterOptions = $.fullCalendar.getClusterDefinitions();

			var isClustering =
				(view.name.indexOf('Horizon') > -1 ||
					view.name === 'basicResourceHor') &&
				clusterOptions.type !== 'day';

			var colors = getColors();

			if (view.name === 'agendaResourceHor') {
				showTimes = true;
				var gridTimes = config.gridTimes;
				durationType = 'minutes';
				slotDuration = moment
					.duration(config.slotDuration)
					.as(durationType);

				end = moment(start).add(
					gridTimes[gridTimes.length - 1].as(durationType),
					durationType
				);
				start = moment(start).add(
					gridTimes[0].as(durationType),
					durationType
				);

				viewDurationCount =
					end.diff(start, durationType) / slotDuration;

				propertyFormat = 'YYYYMMDDTHHmm';
				dateStringFormat = 'LT';
			} else if (isClustering) {
				slotDuration = clusterOptions.typeOffset;
				viewDurationCount = colCount - 1;
				durationType = clusterOptions.type;

				propertyFormat = 'YYYYMMDD';
				dateStringFormat = config.dateStringFormat;
			} else {
				slotDuration = 1;
				durationType = 'days';
				viewDurationCount =
					end.diff(start, durationType) / slotDuration;

				propertyFormat = 'YYYYMMDD';
				dateStringFormat = config.dateStringFormat;
			}

			//Resource views
			if (
				view.name.indexOf('Resource') > -1 &&
				view.name !== 'basicResourceDays'
			) {
				breakoutType = 'resource';
				breakoutItems = manageResources.getViewed();
			} else if (
				view.name.indexOf('Horizon') > -1 &&
				breakoutField === 'resource'
			) {
				breakoutType = 'resource';
				breakoutItems = manageResources.getFiltered(false, 'resources');
			} else if (
				view.name.indexOf('Horizon') > -1 &&
				breakoutField === 'status'
			) {
				breakoutType = 'status';
				breakoutItems = manageResources.getFiltered(false, 'statuses');
			} else if (
				view.name.indexOf('Horizon') > -1 &&
				breakoutField === 'schedule'
			) {
				breakoutType = 'schedule';
				breakoutItems = config.schedulesAll();
			} else if (view.name.indexOf('Horizon') > -1 && breakoutField) {
				breakoutType = 'custom';
				breakoutItems = config.customFieldsAll(
					seedcodeCalendar.get('element').fullCalendar('clientEvents')
				);
			} else {
				breakoutType = false;
				breakoutItems = [];
				breakoutItems.push({
					id: defaultBreakoutID,
					name: defaultBreakoutID,
				});
				// breakoutItems = {};
				// breakoutItems[defaultBreakoutID] = {
				// 	id: defaultBreakoutID,
				// 	name: defaultBreakoutID,
				// };
			}

			//View specific settings
			if (
				view.name === 'agendaResourceVert' ||
				view.name === 'basicResourceVert'
			) {
				maxColCount = maxColCount * config.resourceDays;
				columnGroupSize = colCount;
				hasColumnOffset = true;
			} else if (view.name === 'basicResourceDays') {
				var summaryOnly = true;
				columnsAreBreakout = true;
			} else if (
				view.name === 'basicResourceHor' &&
				config.measureCombineResources
			) {
				var combineResources = true;
			} else if (view.name.indexOf('Horizon') > -1) {
				//Adjust if we are viewing long ranges

				if (clusterOptions.type === 'year') {
					//We are in year scale or higher
					rangeType = 'year';
					includeLastColumn = false;
					dateStringFormat = 'YYYY';
				} else if (
					(clusterOptions.type === 'day' &&
						config.measureAggregate === 'auto' &&
						viewDurationCount >= 126) ||
					config.measureAggregate === 'month' ||
					clusterOptions.type === 'month'
				) {
					//We are over 18 weeks so show in month range
					rangeType = 'month';
					includeLastColumn = false;
					dateStringFormat = 'MMMM, YYYY';
				} else if (
					(clusterOptions.type === 'day' &&
						config.measureAggregate === 'auto' &&
						viewDurationCount >= 35) ||
					config.measureAggregate === 'week' ||
					clusterOptions.type === 'week'
				) {
					//We are over 5 weeks show in week range
					rangeType = 'week';
					includeLastColumn = false;
					dateStringFormat = '[' + config.weekText + '] ' + 'WW';
				}
			}

			//Adjust column padding
			if (colWidth < 15) {
				paddingColumnCount = 14;
			} else if (colWidth < 30) {
				paddingColumnCount = 10;
			} else if (colWidth < 60) {
				paddingColumnCount = 8;
			} else {
				paddingColumnCount = 8;
			}

			//Set dayGrid
			for (
				var breakoutPosition = 0;
				breakoutPosition < breakoutItems.length;
				breakoutPosition++
			) {
				var breakoutItem = breakoutItems[breakoutPosition];

				columnCount = 0;
				columnsBefore = 0;
				columnsAfter = 0;
				durationCount = 0;
				hasBefore = undefined;
				hasAfter = undefined;
				startColumn = undefined;
				endColumn = undefined;

				afterLast = false;

				breakoutID = utilities.stringToID(breakoutItem.name);

				summaryEventIDs[breakoutID] = {};
				summaryEventCount[breakoutID] = 0;
				dayGrid[breakoutID] = {
					name: breakoutItem.name,
					display: breakoutItem.display
						? breakoutItem.display
						: breakoutItem.name,
					shortName: breakoutItem.shortName,
					folderID: breakoutItem.folderID,
					totalCount: 0,
					sort: columnOffsetCount,
					gridData: {},
					gridDataOrder: [],
				};

				//check if we have a color available, if not start over
				if (!colors[colorCount]) {
					colorCount = 0;
				}
				dayGrid[breakoutID].color =
					breakoutField === 'status' || breakoutField === 'schedule'
						? breakoutItem.color
						: colors[colorCount];
				dayGrid[breakoutID].textColor = utilities.generateTextColor(
					dayGrid[breakoutID].color
				);

				if (hasColumnOffset) {
					dayGrid[breakoutID].offset = columnOffsetCount * colWidth;
				}

				colorCount++;
				columnOffsetCount++;

				//Add first date to grid (used to continue line off the edge)
				for (var i = 0; i < paddingColumnCount / 2; i++) {
					dayGrid[breakoutID].gridDataOrder.push('previous-' + i);
					dayGrid[breakoutID].gridData['previous-' + i] = {
						count: 0,
						isEstimate: true,
						isPadding: true,
						//date: date,
						//beforeDuration: startColumn,
						// afterDuration: 0,
						// dateString: date.format(dateStringFormat),
					};
				}

				// run grid functions that contain the main formatting to set data into
				if (columnsAreBreakout) {
					createResourceGrid();
				} else {
					createStandardGrid();
				}

				for (var i = 0; i < paddingColumnCount / 2; i++) {
					//Add first date to grid (used to continue line off the edge)
					dayGrid[breakoutID].gridDataOrder.push('next-' + i);
					dayGrid[breakoutID].gridData['next-' + i] = {
						count: 0,
						isEstimate: true,
						isPadding: true,
						//date: date,
						//beforeDuration: startColumn,
						// afterDuration: 0,
						// dateString: date.format(dateStringFormat),
					};
				}
			}

			if (isClustering) {
				columnGroupSize = 1;
				endColumn = colCount - 1;
			}

			//Make sure our start column has a value
			startColumn = startColumn ? startColumn : 0;

			//Set end column if one isn't set. This could happen in week or month view when the view ends before the first column of the next duration
			if (!endColumn) {
				endColumn = maxColCount - 1;
				maxColumnPad = colWidth;
			}

			if (hasBefore && hasAfter) {
				widthOffset =
					durationColumns * (paddingColumnCount + 2) * colWidth;
				leftOffset =
					middleDurationOffset * colWidth -
					durationColumns * (paddingColumnCount / 2 + 1) * colWidth;
			} else if (hasAfter) {
				widthOffset =
					durationColumns * (paddingColumnCount + 1) * colWidth;
				leftOffset =
					middleDurationOffset * colWidth -
					durationColumns * (paddingColumnCount / 2) * colWidth;
			} else if (hasBefore) {
				widthOffset =
					durationColumns * (paddingColumnCount + 1) * colWidth;
				leftOffset =
					middleDurationOffset * colWidth -
					durationColumns * (paddingColumnCount / 2 + 1) * colWidth;
			} else {
				widthOffset =
					durationColumns *
					paddingColumnCount *
					(colWidth * columnGroupSize);
				leftOffset =
					middleDurationOffset * (colWidth * columnGroupSize) -
					durationColumns *
						(paddingColumnCount / 2) *
						(colWidth * columnGroupSize);
			}

			if (isClustering) {
				widthOffset = widthOffset + colWidth;
			}

			startPosition = view.colLeft(startColumn) + leftOffset;

			contentWidth =
				view.colLeft(endColumn) +
				maxColumnPad -
				view.colLeft(startColumn) +
				widthOffset;

			visibleChartPointCount = durationCount;
			if (middleDurationOffset) {
				if (
					(columnsAfter < middleDurationOffset && columnsAfter > 0) ||
					endsOnStartRange
				) {
					// We track the start day of the period so we need to subtract 1 from that count
					// if we don't reach the mid point of the range. For example the mid point of a week is 3 or month is 15
					visibleChartPointCount--;
				}
				if (
					columnsBefore > middleDurationOffset ||
					start.day() === middleDurationOffset
				) {
					// Add one to the count if we show more columns than the mid point before our first full range (week, month etc.)
					visibleChartPointCount++;
				}
			}

			thresholdTotal = visibleChartPointCount * threshold;

			createEventSet(start, end, durationType, processEvents, refetch);

			//Create resource grid
			function createResourceGrid() {
				var resources = manageResources.getViewed();

				var resourceDateID;
				var resource;

				rangeType = 'resource';

				// thresholdTotal = 0;

				resourceDateHash = {};

				//Get visible dates
				for (var i = 0; i <= viewDurationCount; i++) {
					date = moment(start).add(i * slotDuration, durationType);

					//Move on if weekends aren't shown and it's a weekend day
					if (
						!config.weekends &
						(date.day() === 6 || date.day() === 0)
					) {
						continue;
					}

					// thresholdTotal += threshold; // Add one because end date is exclusive
					resourceDateID = date.format(propertyFormat);

					resourceDateHash[resourceDateID] = resourceDateID;
				}

				//Get visible resources
				for (var property in resources) {
					includeDate = true;
					isEstimate = false;
					resource = resources[property].name;

					durationCount++;
					durationColumns = 1;
					// widthOffset = colWidth * 3;
					// leftOffset = 0 - colWidth;
					dateID = resource;
					startColumn = 0;
					dateString = resource;

					columnCount++;

					//Set a hash to match the day to a dateID (we can look up a specific day to see the associated week for example)
					dateHash[resource] = resource;

					if (dateID && includeDate) {
						//Add date to grid
						if (
							dayGrid[breakoutID].gridDataOrder[
								dayGrid[breakoutID].gridDataOrder.length - 1
							] !== dateID
						) {
							dayGrid[breakoutID].gridDataOrder.push(dateID);
						}

						dayGrid[breakoutID].gridData[dateID] = {
							count: 0,
							date: date,
							beforeDuration: startColumn,
							afterDuration: 0,
							dateString: dateString,
							isEstimate: isEstimate,
						};
					}
				}
			}

			//Create standard grid
			function createStandardGrid() {
				var durationOffset =
					clusterOptions.daysEstimate > 1
						? clusterOptions.daysEstimate
						: durationColumns;

				// thresholdTotal = 0;

				for (var i = 0; i <= viewDurationCount; i++) {
					includeDate = true;
					isEstimate = false;
					date = moment(start).add(i * slotDuration, durationType);

					//Move on if weekends aren't shown and it's a weekend day (only at column day scale)
					if (
						!isClustering &&
						!config.weekends &
							(date.day() === 6 || date.day() === 0)
					) {
						continue;
					}

					endsOnStartRange = false;

					// thresholdTotal += threshold; // Add one because end date is exclusive

					// Target range type is week
					if (!isClustering && rangeType === 'week') {
						durationColumns = !config.weekends ? 5 : 7;
						middleDurationOffset = !config.weekends
							? weekMiddleDay - 1
							: weekMiddleDay;

						var weekHeaderFormat = 'MMMM Do YYYY';
						var weekHeaderString = config.weekNumbers
							? config.weekText
							: config.weekOfText;
						dateString =
							weekHeaderString +
							' ' +
							moment(date)
								.day(weekFirstDay)
								.format(
									$.fullCalendar.localizeDateFormat(
										weekHeaderFormat
									)
								);

						//Check if we are at the first day of the week yet
						if (
							startColumn === undefined &&
							date.day() !== weekFirstDay
						) {
							columnsBefore++;
							//If we have less than two columns before the week start we don't want to count it as it's not enough to get an average
							if (columnsBefore <= 1) {
								dateID = 'before';
								includeDate = false;
								isEstimate = true;
							} else {
								dateID = 'before';
								hasBefore = true;
								isEstimate = true;
							}
						}
						//If we have passed the last full week in view
						else if (afterLast) {
							columnsAfter++;
							//If we have less than two columns after the week end we don't want to count it as it's not enough to get an average
							if (columnsAfter <= 1) {
								dateID = 'after';
								includeDate = false;
								isEstimate = true;
							} else {
								dateID = 'after';
								hasAfter = true;
								isEstimate = true;
							}
						}
						//We are on the first day of the week
						else if (date.day() === weekFirstDay) {
							durationCount++;
							dateID = date.format(propertyFormat);
							//Mark the first column for the full weeks we are counting
							if (startColumn === undefined) {
								startColumn = columnCount;
							}

							//Mark the last column for full weeks in view (start of the last full week)
							if (
								(!config.weekends &&
									viewDurationCount - i < 4) ||
								(config.weekends && viewDurationCount - i < 6)
							) {
								dateID = '';
								afterLast = true;
								endColumn = columnCount;
							}

							endsOnStartRange = true;
						}
					}

					// Target range type is month
					else if (!isClustering && rangeType === 'month') {
						durationColumns = !config.weekends ? 22 : 30;
						middleDurationOffset = !config.weekends ? 11 : 15;

						dateString = date.format(dateStringFormat);
						endsOnStartRange = false;

						//Check if we are at the first day of the month yet
						if (startColumn === undefined && date.date() !== 1) {
							columnsBefore++;
							//If we have less than 4 columns before the week month we don't want to count it as it's not enough to get an average
							if (columnsBefore <= 4) {
								dateID = 'before';
								includeDate = false;
								isEstimate = true;
							} else {
								dateID = 'before';
								hasBefore = true;
								isEstimate = true;
							}
						}
						//If we have passed the last full month in view
						else if (afterLast) {
							columnsAfter++;
							//If we have less than four columns after the week end we don't want to count it as it's not enough to get an average
							if (columnsAfter <= 4) {
								dateID = 'after';
								includeDate = false;
								isEstimate = true;
							} else {
								dateID = 'after';
								hasAfter = true;
								isEstimate = true;
							}
						}
						//We are on the first day of the month
						else if (date.date() === 1) {
							durationCount++;
							dateID = date.format(propertyFormat);
							//Mark the first column for the full months we are counting
							if (startColumn === undefined) {
								startColumn = columnCount;
							}
							//Mark the last column for full months in view (start of the last full week)
							if (
								(!config.weekends &&
									viewDurationCount - i < 22) ||
								(config.weekends && viewDurationCount - i < 30)
							) {
								dateID = '';
								afterLast = true;
								endColumn = columnCount;
							}
							endsOnStartRange = true;
						}
					}
					// Target range type is is a cluster
					else if (isClustering) {
						durationColumns = 1;
						middleDurationOffset = 0;

						if (clusterOptions.type === 'week') {
							var weekHeaderString = config.weekNumbers
								? config.weekText
								: config.weekOfText;
							dateString =
								weekHeaderString +
								' ' +
								moment(date)
									.day(weekFirstDay)
									.format(
										$.fullCalendar.localizeDateFormat(
											'MMMM Do YYYY'
										)
									);
						} else {
							dateString = date.format(dateStringFormat);
						}

						// If the start date doesn't match the range start date
						if (
							startColumn === undefined &&
							startOverride &&
							date.isBefore(startOverride)
						) {
							columnsBefore =
								durationOffset -
								startOverride.diff(date, 'days');
							//If we have less than 4 columns before the week month we don't want to count it as it's not enough to get an average
							// if (columnsBefore <= 4) {
							//   dateID = 'before';
							//   includeDate = false;
							//   isEstimate = true;
							// }
							// else {
							dateID = 'before';
							hasBefore = true;
							isEstimate = true;
							// }
						} else {
							durationCount++;
							if (clusterOptions.type === 'year') {
								dateID = date
									.clone()
									.startOf(durationType)
									.year(
										Math.floor(date.year() / slotDuration) *
											slotDuration
									)
									.format(propertyFormat);
							} else {
								dateID = date
									.clone()
									.startOf(durationType)
									.format(propertyFormat);
							}
							//Mark the first column for the full months we are counting
							if (startColumn === undefined) {
								startColumn = columnCount;
							}
						}
					}
					// Target range type is day
					else {
						durationCount++;
						durationColumns = 1;
						// widthOffset = colWidth * 3;
						// leftOffset = 0 - colWidth;
						dateID = date.format(propertyFormat);
						startColumn = 0;
						dateString = date.format(dateStringFormat);
					}

					columnCount++;

					//Set a hash to match the day to a dateID (we can look up a specific day to see the associated week for example)
					dateHash[date.format(propertyFormat)] = dateID;

					if (dateID && includeDate) {
						//Add date to grid
						if (
							dayGrid[breakoutID].gridDataOrder[
								dayGrid[breakoutID].gridDataOrder.length - 1
							] !== dateID
						) {
							dayGrid[breakoutID].gridDataOrder.push(dateID);
						}

						dayGrid[breakoutID].gridData[dateID] = {
							count: 0,
							date: date,
							beforeDuration: startColumn,
							afterDuration: 0,
							dateString: dateString,
							isEstimate: isEstimate,
						};
					}
				}
			}

			function processEvents(events) {
				// Will go through the full length of the event and add proper counts to the columns based on how that is split up
				var result = {};
				var event;
				var eventDuration;
				var eventDurationCount;
				var eventStart;
				var eventEnd;
				var partialRange;
				var gridParent;
				var gridParentID;
				var dateString;
				var eventStartCorrected;
				var eventEndCorrected;
				var eventEndInclusive;
				var fieldDurationCount;
				var parentThresholdRemaining = 0;
				var totalCount = 0;
				var resourceDateID;
				var isValidDate;
				var schedule;
				var gridItem;
				var shareSources = seedcodeCalendar.get('shareSources');

				var decimalPlaces =
					config.measureDecimalPlaces === '' ||
					config.measureDecimalPlaces > 20
						? 8
						: config.measureDecimalPlaces;
				var hasDecimalPlaces =
					config.measureDecimalPlaces !== '' &&
					config.measureDecimalPlaces !== undefined;

				for (var i = 0; i < events.length; i++) {
					event = events[i];
					eventStart = moment(event.start);
					eventEnd = moment(event.end);
					eventEndInclusive = event.allDay
						? moment(event.end).subtract(1, 'day')
						: moment(event.end);

					schedule =
						config.isShare && event.shareSchedule
							? event.shareSchedule
							: event.schedule;

					//Convert minutes to matching hash (converts minutes to selected time interval)
					var minutes = eventStart.minutes();

					if (
						isClustering &&
						(clusterOptions.type === 'week' ||
							clusterOptions.type === 'month')
					) {
						eventDurationCount =
							Math.floor(
								eventEndInclusive
									.clone()
									.startOf(durationType)
									.diff(
										eventStart
											.clone()
											.startOf(durationType),
										durationType
									)
							) + 1; // Add plus one to make exlusive
					} else if (isClustering && clusterOptions.type === 'year') {
						eventDurationCount =
							Math.floor(
								eventEndInclusive
									.clone()
									.year(
										Math.floor(
											eventEndInclusive.year() /
												slotDuration
										) * slotDuration
									)
									.startOf(durationType)
									.diff(
										eventStart
											.clone()
											.year(
												Math.floor(
													eventStart.year() /
														slotDuration
												) * slotDuration
											)
											.startOf(durationType),
										durationType
									) / slotDuration
							) + 1; // Add plus one to make exlusive
					} else {
						eventStart.minutes(
							Math.floor(minutes / slotDuration) * slotDuration
						);
						eventDurationCount = Math.floor(
							eventEnd.diff(eventStart, durationType) /
								slotDuration
						); //Used for mapping counts appropriately - Minutes for timed views days for all day views
					}

					if (breakoutField) {
						if (breakoutField === 'schedule') {
							gridParent = [schedule.name];
						} else {
							if (breakout && schedule.breakout) {
								// Breakout by field not a built in list like resource, status, schedule
								gridParent = Array.isArray(
									event[schedule.breakout.field]
								)
									? event[schedule.breakout.field]
									: [event[schedule.breakout.field]];
								// Make sure to not add zero item array. We normalize empty string for "none" later
								if (!gridParent.length) {
									gridParent = [''];
								}
							} else if (breakout && !schedule.breakout) {
								// Breakout by field that has data in the field but the label doesn't match the current selection
								gridParent = [''];
							} else {
								// Breakout by list like resource and status
								gridParent = Array.isArray(event[breakoutField])
									? event[breakoutField]
									: [event[breakoutField]];
							}
						}
					} else {
						gridParent = breakoutType
							? event[breakoutType]
							: [defaultBreakoutID];
					}

					for (var iii = 0; iii < gridParent.length; iii++) {
						gridParentID = utilities.stringToID(gridParent[iii]);

						// Normalize blank breakout labels
						if (
							gridParentID === '' ||
							gridParentID === undefined ||
							gridParentID === null
						) {
							gridParentID = config.noFilterLabel;
						}

						if (dayGrid[gridParentID]) {
							//Loop through the length of the event so long events count on each slot they appear in
							var ii = 0;

							do {
								date = moment(eventStart).add(
									ii * slotDuration,
									durationType
								);
								dateString = date.format(propertyFormat);

								if (columnsAreBreakout) {
									// ii = eventDurationCount;
									dateID = event.resource;
									resourceDateID =
										resourceDateHash[
											date.format(propertyFormat)
										];
									isValidDate = resourceDateID ? true : false;
								} else if (isClustering) {
									if (clusterOptions.type === 'year') {
										dateID =
											dateHash[
												date
													.clone()
													.year(
														Math.floor(
															date.year() /
																slotDuration
														) * slotDuration
													)
													.startOf(durationType)
													.format(propertyFormat)
											];
									} else {
										dateID =
											dateHash[
												date
													.clone()
													.startOf(
														clusterOptions.type
													)
													.format(propertyFormat)
											];
									}
									isValidDate = dateID ? true : false;
								} else {
									dateID =
										dateHash[date.format(propertyFormat)];
									isValidDate = dateID ? true : false;
								}
								if (isValidDate) {
									if (dateID && Array.isArray(dateID)) {
										for (
											var iiii = 0;
											iiii < dateID.length;
											iiii++
										) {
											applyToGrid(dateID[iiii]);
										}
									} else {
										applyToGrid(dateID);
									}
								}
								ii++;
							} while (ii < eventDurationCount);
						}
						if (dayGrid[gridParentID]) {
							dayGrid[gridParentID].totalCount =
								summaryEventCount[gridParentID]
									? summaryEventCount[gridParentID]
									: 0;
						}
					}
				}

				for (var parentItem in dayGrid) {
					parentThresholdRemaining = 0;

					// Set threshold remaining counts. This is used for a total of above or below threshold
					if (dayGrid[parentItem].gridData && threshold) {
						for (var dateID in dayGrid[parentItem].gridData) {
							gridItem = dayGrid[parentItem].gridData[dateID];
							// Subtract grid item count from threshold remaining. This is subtracted from zero
							// Once we get a negative number we add the total threshold count back to this total subtracted amount
							parentThresholdRemaining -= gridItem.count;

							// Record that this breakout item has an estimated value
							if (
								dateID === 'before' ||
								(dateID === 'after' && gridItem.isEstmate)
							) {
								partialRange = true;
							}
						}

						//
						// Add threshold totals to breakout items
						//

						// Set threshold remaining for this parent item
						dayGrid[parentItem].thresholdRemaining =
							(thresholdTotal * 100000 +
								parentThresholdRemaining * 100000) /
							100000;
					}
				}

				// Combine resources into single series
				var keys = Object.keys(dayGrid);
				if (keys.length > 1 && combineResources) {
					//initialize new grid data
					var gridData = {};
					var firstSeries = dayGrid[keys[0]];
					var thisGridData = firstSeries.gridData;
					for (var dateId in thisGridData) {
						var thisDate = thisGridData[dateId];
						gridData[dateId] = {};
						for (var property in thisDate) {
							if (property == 'date') {
								gridData[dateId][property] =
									thisDate[property].clone();
							} else if (property == 'count') {
								gridData[dateId][property] = 0;
							} else {
								gridData[dateId][property] = thisDate[property];
							}
						}
					}
					//summarize into new gridData
					for (var key in keys) {
						var thisSeries = dayGrid[keys[key]];
						thisGridData = thisSeries.gridData;
						for (var dateId in thisGridData) {
							var count = thisGridData[dateId].count;
							gridData[dateId].count =
								gridData[dateId].count + count;
						}
					}
					//remove all but first series
					for (var i = 1; i < keys.length; i++) {
						delete dayGrid[keys[i]];
					}
					dayGrid[keys[0]].gridData = gridData;
					dayGrid[keys[0]].folderID = null;
					dayGrid[keys[0]].name = 'data';
					dayGrid.data = dayGrid[keys[0]];
					delete dayGrid[keys[0]];
				}

				// Create the result that is used to create the charts
				result.dayGrid = dayGrid;
				// result.total = summaryEventIDs ? Object.keys(summaryEventIDs).length : 0;
				result.showTimes = showTimes;
				result.summaryOnly = summaryOnly;
				result.combineResources = combineResources;
				result.breakout = breakoutType ? true : false;
				result.breakoutType = breakoutType;
				result.totalCount = totalCount;
				result.startColumn = startColumn;
				result.endColumn = endColumn;
				result.includeLastColumn = includeLastColumn;

				result.startPosition = startPosition;
				result.widthOffset = widthOffset;
				result.contentWidth = contentWidth;

				result.paddingColumnCount = paddingColumnCount;

				// Set threshold total
				result.thresholdTotal = thresholdTotal;

				if (durationType === 'minutes') {
					// Set how many chart points are visible
					result.visibleChartPointCount =
						durationCount / (60 / slotDuration); //visibleChartPointCount;

					// Use the following to build out the "Threshold over x days"
					result.rangeType = 'hour';
				} else {
					// Set how many chart points are visible
					result.visibleChartPointCount = visibleChartPointCount;

					// Use the following to build out the "Threshold over x days"
					result.rangeType = rangeType;
				}

				// Does the current chart not include full range based on type
				result.partialRange = partialRange;

				//Run callback to return our process grid of data
				if (callback) {
					callback(result);
				}

				function applyToGrid(dateID) {
					//Check to see if the event is within our current view range
					if (!dateID) {
						return;
					}
					// Apply values to grid
					if (dayGrid[gridParentID].gridData.hasOwnProperty(dateID)) {
						//Increment by 1 when counting events
						if (countType === 'count') {
							eventDuration = 1;
							dayGrid[gridParentID].gridData[dateID].count++;
							if (
								!summaryEventIDs[gridParentID][
									events[i].eventID
								]
							) {
								summaryEventCount[gridParentID]++;
								summaryEventIDs[gridParentID][
									events[i].eventID
								] = true;
							}
							if (!eventIDs[events[i].eventID]) {
								totalCount++;
							}
						}
						//Increment by minute duration within cell for duration
						else if (countType === 'duration') {
							decimalPlaces = 2;
							dateEnd = moment(eventStart).add(
								(ii + 1) * slotDuration,
								durationType
							);
							if (eventStart.isBefore(date)) {
								eventStartCorrected = date;
							} else {
								eventStartCorrected = eventStart;
							}
							if (eventEnd.isBefore(dateEnd)) {
								eventEndCorrected = eventEnd;
							} else {
								eventEndCorrected = dateEnd;
							}

							//we could have logic here to only count time if it is a timed event (or a setting that says include all day)
							eventDuration = !event.allDay
								? Number(
										parseFloat(
											eventEndCorrected.diff(
												eventStartCorrected,
												'minutes'
											) / 60
										).toFixed(decimalPlaces)
									)
								: 0;
							dayGrid[gridParentID].gridData[dateID].count =
								Number(
									(
										dayGrid[gridParentID].gridData[dateID]
											.count + eventDuration
									).toFixed(decimalPlaces)
								);
							summaryEventCount[gridParentID] = Number(
								(
									summaryEventCount[gridParentID] +
									eventDuration
								).toFixed(decimalPlaces)
							);
							totalCount = Number(
								(totalCount + eventDuration).toFixed(
									decimalPlaces
								)
							);
						} else if (countType === 'field') {
							//Zero duration because the if statement doesn't trigger if the field isn't found which could lead to false counts
							fieldDurationCount = 0;

							if (event.hasOwnProperty(measureField)) {
								// if (columnsAreBreakout) {
								//   console.log('eventDurationCount', eventDurationCount);
								//   fieldDurationCount = event[measureField] ? Number(event[measureField]) : 0;
								// }
								// else {
								fieldDurationCount = event[measureField]
									? event[measureField] /
										Math.max(eventDurationCount, 1)
									: 0;
								// }
							} else {
								//Get field from custom fields
								var customFields =
									config.isShare && event.shareSchedule
										? utilities.getValidShareCustomFields(
												shareSources[
													event.shareScheduleID
												]
													? shareSources[
															event
																.shareScheduleID
														]
													: shareSources[
															event.shareSourceID
														],
												event
											)
										: event.schedule.customFields;

								for (var property in customFields) {
									if (
										measureField ===
										customFields[property].name
									) {
										if (columnsAreBreakout) {
											fieldDurationCount = event[property]
												? Number(event[property])
												: 0;
										} else {
											fieldDurationCount = event[property]
												? event[property] /
													Math.max(
														eventDurationCount,
														1
													)
												: 0;
										}
										break;
									}
								}
							}

							//Set to zero if this is not a valid number
							if (isNaN(fieldDurationCount)) {
								fieldDurationCount = 0;
							}

							dayGrid[gridParentID].gridData[dateID].count =
								Number(
									(
										dayGrid[gridParentID].gridData[dateID]
											.count + fieldDurationCount
									).toFixed(decimalPlaces)
								);
							summaryEventCount[gridParentID] = Number(
								(
									summaryEventCount[gridParentID] +
									fieldDurationCount
								).toFixed(decimalPlaces)
							);
							totalCount = Number(
								(totalCount + fieldDurationCount).toFixed(
									decimalPlaces
								)
							);
						}

						eventIDs[events[i].eventID] = true;
					}
				}
			}
		}

		function createEventSet(start, end, durationType, callback, refetch) {
			var eventSet = [];
			var event;
			getEvents(start, end, processResult, refetch);

			function processResult(events) {
				//Create event lists and change set
				for (var i = 0; i < events.length; i++) {
					event = events[i];
					//Check if event is in the current view range
					if (
						event.end.isBefore(start, durationType) ||
						event.start.isAfter(end, durationType) ||
						event.schedule.isMapOnly ||
						!manageFilters.isEventShown(event, true)
					) {
						continue;
					}

					//Todo need to take into account that a resource doesn't exist
					eventSet.push(event);
				}
				if (callback) {
					callback(eventSet);
				}
			}
		}

		function getEvents(start, end, callback, refetch) {
			var schedules = seedcodeCalendar.get('schedules');
			var config = seedcodeCalendar.get('config');
			var calendarElement = seedcodeCalendar.get('element');
			var schedulesQueried = 0;
			var result = [];
			var finalResult = [];
			var selectedSchedules = [];

			if (refetch) {
				for (var i = 0; i < schedules.length; i++) {
					if (schedules[i].status && schedules[i].status.selected) {
						selectedSchedules.push(schedules[i]);
						schedules[i].source.events(
							start,
							end,
							config.timezone,
							gatherEvents
						);
					}
				}
			} else {
				callback(calendarElement.fullCalendar('clientEvents'));
			}

			function gatherEvents(data) {
				schedulesQueried++;
				result = result.concat(data);
				if (schedulesQueried === selectedSchedules.length) {
					//all callbacks done
					for (var i = 0; i < result.length; i++) {
						if (manageFilters.isEventShown(result[i])) {
							result[i].start = moment(result[i].start);
							result[i]._start = result[i].start;
							result[i].end = moment(result[i].end);
							if (result[i].allDay) {
								//Customized for SeedCode. We want to display all day events as inclusive rather than exclusive end day
								//this logic is also being used in fullcalendar/event-manager.js and should be consolidated.
								result[i]._allDay = result[i].allDay;
								if (result[i].end) {
									result[i].end.add(1, 'days');
								}
							}
							result[i]._end = result[i].end;
							result[i]._id = result[i].eventID;
							result[i].source = result[i].schedule.source;
							finalResult.push(result[i]);
						}
					}
					if (callback) {
						callback(finalResult);
					}
				}
			}
		}

		function showMeasure(e) {
			var scopeCallbackFunction = null;
			var popover = {
				controller: 'MeasureCtrl',
				target: null, //e.currentTarget,
				container: $('#calendar'),
				type: 'info', // modal or popover or info
				// width: 700,
				// positionX: e.pageX,
				// positionY: e.pageY,
				scopeCallback: scopeCallbackFunction,
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};

			utilities.popover(
				popover,
				'<div ng-include="\'app/measure/measure.html\'"></div>'
			);

			var config = seedcodeCalendar.get('config');
			if (!config.showMeasure) {
				config.showMeasure = {};
			}
			config.showMeasure.status = true;
			dataStore.saveState(
				'showMeasure',
				JSON.stringify(config.showMeasure)
			);
		}

		function hideMeasure() {
			$rootScope.$broadcast('calendarInfoClose', true);
			$rootScope.$broadcast('calendarInfoSettings', false);

			var config = seedcodeCalendar.get('config');
			if (!config.showMeasure) {
				config.showMeasure = {};
			}
			config.showMeasure.status = false;
			dataStore.saveState(
				'showMeasure',
				JSON.stringify(config.showMeasure)
			);
		}
	}
})();
